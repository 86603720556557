<template>
  <div class="edu-frame">
    <div class="pay-title">{{activityMaster.organisers}}</div>
    <div class="pay-frame">
      <div class="pay-tips">
        <div style="flex: 1;margin-left: 15px;">消费金额：</div>
        <div style="margin-right: 15px;"><span style="color: gray;font-size: 30px;">{{totalMoney}}</span>元</div>
      </div>
      <div style="font-size: 14px;color: gray;">（优惠金额点击"付款"按钮查看）</div>
      <div class="pay-line"></div>
    </div>

    <div class="pay-type" v-if="totalMoney > 0">
      <div v-if="isWeb" style="display: flex;align-items: center;flex: 1;"><img class="pay-img" src="../../statics/images/alipay.png" alt="">支付宝支付</div>
      <div v-else style="display: flex;align-items: center;flex: 1;"><img class="pay-img" src="../../statics/images/wechatpay.png" alt="">微信支付</div>
      <van-checkbox style="margin-right: 15px;" v-model="checked" @change="checked=true"></van-checkbox>
    </div>
    <!-- 提交按钮 -->
    <div v-if="totalMoney == 0" style="width: 90%;margin: 30px auto auto auto;">
      <van-button :loading="submitting" type="info" block size="large" @click="toPay(0)">确定提交课程</van-button>
    </div>
    <div v-else style="width: 90%;margin: 30px auto auto auto;">
      <van-button v-if="isWeb" :loading="submitting" type="info" block size="large" @click="toPay(1)">付款</van-button>
      <van-button v-else :loading="submitting" type="primary" block size="large" @click="toPay(2)">付款</van-button>
    </div>
    <!-- 提交按钮 -->

    <!--支付宝支付表单-->
    <div id="alipayform"></div>
    <!--支付宝支付表单-->
  </div>
</template>
<script>
import { Checkbox, Button } from 'vant';
import ActivityApi from "@/api/ActivityApi";
import Tools from "@/api/Tools";
import EduConstants from "@/api/EduConstants";
import wx from "weixin-js-sdk"
import BaomingApi from "@/api/BaomingApi";
export default {
  name: 'actpay',
  components: {
    VanCheckbox: Checkbox,
    VanButton: Button
  },
  data() {
    return {
      condition: '',
      checked: true,
      activityBuyDetails: null,
      totalMoney: 0,
      activityMaster: {},
      isWeb: !EduConstants.isMobile(),
      submitting: false
    }
  },
  methods: {
    toPay(payType) {
      if (this.submitting) {
        return
      }

      var openId = Tools.getSession('openId')
      if (openId == null || openId == '' || openId == undefined) {
        this.$dialog.alert({message: "未能确定您的openId，请从公众号菜单进入进行操作。"})
        return;
      }
      // alert('openId == ' + openId)
      var buyMaster = { activitySettingMasterId : this.$route.query.activitySettingMasterId, totalMoney: this.totalMoney, payType: payType, wxOpenId: openId }
      var buyMasterDetails = []
      for (var i = 0; i < this.activityBuyDetails.length; i++) {
        var detail = this.activityBuyDetails[i]
        if (detail.itemType == 'SingleSelect') {
          // 单选
          for (var j = 0; j < detail.items.length; j++) {
            var singleselectVal = {
                                      activitySettingDetailId: detail.activitySettingDetailID,
                                      activitySettingItemDetailId: detail.items[j].activitySettingItemDetailID,
                                      selectItem: detail.selectItem == detail.items[j].activitySettingItemDetailID ? 1 : 0
                                    }
            buyMasterDetails.push(singleselectVal)
          }

        } else if (detail.itemType == 'EditText' || detail.itemType == 'Age') {
          var edittextVal = {
                                activitySettingDetailId: detail.activitySettingDetailID,
                                inputText: detail.inputText
                              }
          buyMasterDetails.push(edittextVal)
        } else if (detail.itemType == 'MultiSelect') {
          // 文本框
          for (var k = 0; k < detail.items.length; k++) {
            var selectItems = detail.selectItem.split(',')
            var isSelect = false
            for (var k2 = 0; k2 < selectItems.length; k2++) {
              if (detail.items[k].activitySettingItemDetailID == selectItems[k2]) {
                isSelect = true
                break
              }
            }

            var multiselectVal = {
              activitySettingDetailId: detail.activitySettingDetailID,
              activitySettingItemDetailId: detail.items[k].activitySettingItemDetailID,
              selectItem: isSelect ? 1 : 0
            }
            buyMasterDetails.push(multiselectVal)
          }
        } else if (detail.itemType == 'Switch') {
          var switchVal = {
                            activitySettingDetailId: detail.activitySettingDetailID,
                            selectItem: detail.selectItem
                          }
          buyMasterDetails.push(switchVal)
        } else if (detail.itemType == 'Goods') {
          for (var l = 0; l < detail.items.length; l++) {
            if (detail.items[l].ischeck == true) {
              var goodsVal = {
                                activitySettingDetailId: detail.activitySettingDetailID,
                                activitySettingItemDetailId: detail.items[l].activitySettingItemDetailID,
                                qty: detail.items[l].qty,
                                price: detail.items[l].price,
                              }
              buyMasterDetails.push(goodsVal)
            }
          }
        }
      }
      console.log('buyMasterDetails', buyMasterDetails)
      buyMaster.details = buyMasterDetails
      this.submitting = true
      ActivityApi.buyActivity(buyMaster).then(response => {
        this.submitting = false
          if (response.code == 100) {
            if (payType == 0) {
                if (response.res.data == 'success') {
                  this.$router.push({ name: 'actsuccess' })
                } else {
                  this.$dialog.alert({message: "报读课程失败，请重试。"})
                }
            } else {
              if (payType == 1) {
                const div = document.getElementById('alipayform')
                div.innerHTML = response.res.data
                document.body.appendChild(div)
                document.forms[0].submit()
              } else {
                // alert('wechat')
                var res = response.res.data
                // alert(JSON.stringify(res))
                wx.ready(function () {
                  wx.chooseWXPay({
                    appId: res.appId,
                    timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                    nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
                    package: res.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
                    signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                    paySign: res.paySign, // 支付签名
                    success: function (res) {
                      // 支付成功后的回调函数
                      console.log(res)
                      self.$toast.success('支付成功');
                      this.$router.push({ name: 'actsuccess' })
                    },
                    cancel: function (res) {
                      console.log(res)
                      // 支付失败后的回调函数
                      // self.$toast.fail('支付失败');
                      // location.href = window.location.origin + '/#/order/0';
                    }
                  });
                });

              }
            }

          }
      }).catch(() => {
        this.submitting = false
      })
    },
    getActivity() {
      var activitySettingMasterId = this.$route.query.activitySettingMasterId
      this.submitting = true
      ActivityApi.getActivityById(activitySettingMasterId).then(response => {
        this.submitting = false
        this.activityMaster = response.res
      }).catch(() => {
        this.submitting = false
      })
    },
    createJsapiSignature() {
      BaomingApi.createJsapiSignature({url: window.location.href}).then(response => {
        var res = response.res
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见附录1
          jsApiList: ['checkJsApi', 'chooseWXPay']
          // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
      })
    }
  },
  mounted() {
    this.createJsapiSignature()
    this.getActivity()
    this.activityBuyDetails = JSON.parse(sessionStorage.getItem('activityBuyDetails'))
    console.log(this.activityBuyDetails)
    this.totalMoney = 0
    for (var i = 0; i < this.activityBuyDetails.length; i++) {
      if (this.activityBuyDetails[i].itemType == 'Goods') {
        for (var j = 0; j < this.activityBuyDetails[i].items.length; j++) {
          if (this.activityBuyDetails[i].items[j].ischeck) {
            this.totalMoney += this.activityBuyDetails[i].items[j].price * this.activityBuyDetails[i].items[j].qty
          }
        }
      }
    }
    if (this.totalMoney != null) {
      this.totalMoney = this.totalMoney.toFixed(2)
    }
    // 如果没有金额，则直接提交
    if (this.totalMoney == 0) {
      this.toPay(0)
    }
  }
}
</script>
<style scoped>
.pay-title {
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.pay-frame {
  width: 95%;
  margin: auto;
  border: 1px solid gray;
  border-radius: 10px;
  height: 115px;
  background-color: white;
}
.pay-tips {
  width: 100%;
  display: flex;
  align-items: center;
  height: 70px;
}
.pay-line {
  width: 100%;
  height: 1px;
  background-color: #dddddd;
}
.pay-type {
  display: flex;
  align-items: center;
  background-color: white;
  margin-top: 30px;
  height: 50px;
  border-bottom: 1px solid #dddddd;
}
.pay-img {
  width: 35px;
  margin-right: 5px;
  margin-left: 10px;
}
</style>
